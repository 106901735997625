import React from "react";

import { Link } from "react-router-dom";

import logoFooter from "../../Assets/Images/SBT GAMES.png";

import banner1a from "../../Assets/Images/Banners/GolShow728X90.jpg";
import banner1b from "../../Assets/Images/Banners/GolShow970X250.jpg";
import banner2a from "../../Assets/Images/Banners/PassaouRepassa728X90.jpg";
import banner2b from "../../Assets/Images/Banners/PassaouRepassa970X250.jpg";
import banner3a from "../../Assets/Images/Banners/PolianaCakeCrush728X90.jpg";
import banner3b from "../../Assets/Images/Banners/PolianaCakeCrush970X250.jpg";

import style from "./style.module.scss";

class Footer extends React.Component {
  state = { bannersArr: [], activeBanner: 0, intervalId: 0 };

  detectIOS() {
    if (typeof navigator === "undefined") return false;

    return (
      /iPad|iPhone|iPod/gi.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    );
  }

  componentDidMount() {
    var intervalId = setInterval(this.bannerTimer.bind(this), 7000);
    // store intervalId in the state so it can be accessed later:
    this.setState((prevState) => ({
      ...prevState,
      intervalId,
      bannersArr: [
        {
          imgDesk: banner1a,
          imgMob: banner1b,
          linkAndroid:
            "https://play.google.com/store/apps/details?id=com.liga.goalshow",
          linkIOS: "https://apps.apple.com/br/app/gol-show/id1597471310",
        },
        {
          imgDesk: banner2a,
          imgMob: banner2b,
          linkAndroid:
            "https://play.google.com/store/apps/details?id=com.sbt.passaourepassa",
          linkIOS:
            "https://apps.apple.com/br/app/passa-ou-repassa/id1600552147",
        },
        {
          imgDesk: banner3a,
          imgMob: banner3b,
          linkAndroid:
            "https://play.google.com/store/apps/details?id=com.liga.sbt.polianacakecrush",
          linkIOS:
            "https://apps.apple.com/br/app/poliana-cake-crush/id1597470632",
        },
      ],
      isIOS: this.detectIOS(),
    }));
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  bannerTimer() {
    const totalBanners = this.state.bannersArr.length;
    const { activeBanner } = this.state;

    let newIndex = activeBanner;
    if (activeBanner + 1 < totalBanners) {
      newIndex++;
    } else {
      newIndex = 0;
    }

    this.setState({ activeBanner: newIndex });
  }

  render() {
    const { bannersArr, activeBanner = 0, isIOS } = this.state;

    const { imgDesk, imgMob, linkAndroid, linkIOS } =
      bannersArr[activeBanner] || {};

    return (
      <div className="container">
        {bannersArr.length && (
          <>
            <div className={style.callToAction}>
              <h3 className={style.ctaTitle}>
                Conheça mais jogos do SBT Games:
              </h3>
              <a
                className={style.link}
                href={isIOS ? linkIOS : linkAndroid}
                rel="noreferrer"
                target="_blank"
              >
                <div
                  className={style.ctaBGDesk}
                  style={{
                    backgroundImage: `url(${imgDesk})`,
                  }}
                />
                <div
                  className={style.ctaBGMob}
                  style={{
                    backgroundImage: `url(${imgMob})`,
                  }}
                />
              </a>
            </div>
            {bannersArr.map((item, index) => {
              // this forces browser to load all bgs, improving loading/display times
              return (
                <div
                  key={index}
                  style={{
                    height: 0,
                    visibility: "hidden",
                    backgroundImage: `url(${item.imgDesk})`,
                  }}
                />
              );
            })}
          </>
        )}

        <div className={style.containerFooter}>
          <div>
            <a
              href="https://www.sbt.com.br/politica-de-privacidade"
              target="_blanck"
              className={style.terms}
            >
              Política de Privacidade
            </a>
            <br />
            <Link to="/term" className={style.terms}>
              Termos de Aceite
            </Link>
            <br />
            <a
              href="https://www.sbt.com.br/institucional#quem-somos"
              target="_blanck"
              className={style.terms}
            >
              Sobre o SBT
            </a>
            <br />
            <Link to="/contact" className={style.terms}>
              Contate-nos
            </Link>
            <br />
            <p className={style.terms}>
              Copyright © {new Date().getFullYear()} SBT Todos os direitos
              reservados.
            </p>
          </div>

          <img
            src={logoFooter}
            alt="Logo footer"
            className={style.logoFooter}
          />
        </div>
      </div>
    );
  }
}

export default Footer;
