import React from "react";

import Collapsed from "../../Components/Collapsed/index";
import Footer from "../../Components/Footer/index";

import ImageCard1 from "../../Assets/Images/gameplay.webp";
// import ImageCard2 from "../../Assets/Images/ajuda.webp";
// import tablePoints from "../../Assets/Images/tabela-pontos.webp";

import style from "./style.module.scss";

const data = [
  {
    title: "Como salvo meu progresso no jogo?",
    text: "O progresso do jogo fica vinculado à sua conta. Para manter o progresso, basta realizar o cadastro e conectar-se.",
  },
  {
    title: "Posso transferir meu progresso do jogo para um novo dispositivo?",
    text: "Para transferir o progresso é só ir em conectar e realizar o login com o e-mail e senha cadastrados.",
  },
  {
    title: "O jogo está congelando ou travando. O que devo fazer?",
    text: "Confira se o aparelho tem espaço de armazenamento suficiente e libere memória para que o jogo funcione da melhor maneira possível.",
  },
  {
    title: "Quantas perguntas tem em cada partida?",
    text: "Cada partida é composta por 16 perguntas separadas em 4 níveis: nível 1, nível 2, nível 3 e tudo ou nada onde as  etapas fácil, médio e difícil corresponde a 4 perguntas cada e a etapa tudo ou nada corresponde a pergunta de 1 milhão.",
  },
  {
    title: "Quanto tempo tem cada pergunta?",
    text: "Cada pergunta tem o intervalo de 45s para ser respondida.",
  },
  {
    title: "Qual a diferença do ranking geral para o ranking semanal?",
    text: "O ranking geral corresponde ao seu progresso no jogo em cada temporada, já o ranking semanal é o seu progresso durante a semana onde é reiniciado toda terça às 00:00 hrs (Horário de Brasília).",
  },
  {
    title: "Quantas ajudas o jogo possui?",
    text: "O jogo possui 4 ajudas sendo elas: cartas, placas, convidados e pular.",
  },
  {
    title: "Como desligo o som do jogo?",
    text: "Para desativar o som, é necessário ir até as configurações do jogo, onde é possível desativar/ativar a música, bem como os efeitos sonoros.",
  },
  {
    title: "Como personalizo meu avatar?",
    text: "Para personalizar o avatar, basta ir até a tela inicial do jogo, clicar sobre o seu avatar. Lá é possível alterar o nome do jogador, escolher itens como roupa e acessórios.",
  },
  {
    title: "Como jogo por temas?",
    text: "Para definir o tema das perguntas, basta ir até a tela inicial do jogo, escolher pelo modo clássico ou editar os temas à sua escolha, clicando em editar.",
  },
  {
    title: "Quantos pontos ganho quando o tempo é esgotado?",
    text: "Quando o tempo da questão é finalizado a pontuação da partida fica a mesma de quando o jogo é parado (botão parar).",
  },
  {
    title: "Quantos pulos tenho em cada  partida do jogo (versão gratuita)?",
    text: "Para cada partida são disponibilizados 3 pulos.",
  },
  {
    title: "Posso resgatar o valor do jogo em dinheiro?",
    text: "Não, o valor em dinheiro no jogo corresponde a pontuação e moeda do jogo.",
  },
  {
    title: "Posso alterar o meu nome no jogo?",
    text: "Para alterar o nome do jogador, basta ir até a tela inicial do jogo, clicar sobre o seu avatar e seguir com a alteração do nome.",
  },
];

class Faq extends React.Component {
  render() {
    return (
      <>
        <div className="pb-separate-medium">
          <div className="container">
            <h1 className="text-shadow-5 text-white text-center text-semibold uppercase pt-separate-double mt-not-separate">
              Conheça as regras do game
            </h1>
            <div className="pt-separate-medium">
              <Collapsed data={data} />
            </div>
            <div className="pt-separate-medium">
              <div
                className={`row ${style.containerReverse} pb-separate-medium`}
              >
                <div className="md-5 xs-12">
                  <div className={style.cardImage}>
                    <img
                      src={ImageCard1}
                      alt="Como o jogo funciona"
                      className={`fl ${style.imageCard}`}
                    />
                  </div>
                </div>
                <div className="md-7 xs-12">
                  <h2
                    className="text-white text-semibold"
                    style={{ marginTop: "-15px" }}
                  >
                    Como o jogo funciona?
                  </h2>
                  <p className="text-white text-normal">
                    Cada partida é composta por 16 perguntas. Em cada pergunta,
                    o jogador terá 45 segundos para escolher a alternativa
                    correta. As perguntas estão classificadas em quatro níveis
                    de dificuldade.
                  </p>
                  <p className="text-white text-normal">
                    A cada pergunta respondida corretamente, o jogador garante
                    pontos. Ao parar a partida ou errar a pergunta, o jogador
                    fica com a pontuação referente a última pergunta.
                  </p>
                  <p className="text-white text-normal">
                    Caso o jogador não responda a pergunta antes do tempo
                    acabar, ele fica com a mesma pontuação alcançada quando
                    decidir "parar".
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Faq;
