import React from "react";

import style from "./style.module.scss";

class Collapsed extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <div className={style.faq}>
        {data?.map((item, index) => (
          <div key={index}>
            <input id={index} key={index} type="checkbox" />
            <label htmlFor={index}>
              <div className={style.containerHeading}>
                <p className={style.faqHeading}>{item.title}</p>
              </div>
              <div className={style.faqArrow}></div>
              <div>
                <p className={style.faqText}>{item.text}</p>
                {item.img && (
                  <img
                    src={item.img}
                    alt="Imagem collapsed"
                    className={style.imgCollapsed}
                  />
                )}
              </div>
            </label>
          </div>
        ))}
      </div>
    );
  }
}

export default Collapsed;
