import React from "react";

import GetAllRanking from "../../Services/Requests/Ranking/index";

import RankingBar from "../../Components/RankingBar/index";
import InputSearch from "../../Components/InputSearch/index";
import Loading from "../../Components/Loading/index";
import Footer from "../../Components/Footer/index";

import ImageClearFilter from "../../Assets/Images/clear-icon.png";

import style from "./style.module.scss";

const offilineSearch = (name, term) => {
  return name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) >= 0;
};

class Ranking extends React.Component {
  state = {
    loading: false,
    allUsers: [],
    results: [],
    filter: "",
  };

  async componentDidMount() {
    const filter = this.state.filter;

    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const response = await GetAllRanking(50, 1, filter?.trim());

    this.setState((prevState) => ({
      ...prevState,
      results: response.data,
      allUsers: response.data,
      loading: false,
    }));
  }

  handleChange = ({ target: { value, name } }) => {
    this.setState({ [name]: value });

    if (this.timeout) clearInterval(this.timeout);
    this.timeout = setTimeout(() => {
      this.onFilter();
    }, 500);
  };

  onFilter = () => {
    const filter = this.state.filter?.trim();

    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    let results = [...this.state.allUsers];
    if (filter) {
      results = results.filter((item) => offilineSearch(item.name, filter));
    }

    this.setState((prevState) => ({
      ...prevState,
      loading: false,
      results,
    }));
  };

  onClearSearch = () => {
    document.getElementById("search").value = "";
    this.handleChange({ target: { name: "filter", value: "" } });
  };

  render() {
    const { results, loading } = this.state;
    return (
      <>
        <div className="pb-separate-base">
          <div className="container">
            <h1 className="text-shadow-5 text-white text-center text-semibold uppercase pt-separate-double mt-not-separate">
              Ranking
            </h1>
            <label className={style.search}>
              <InputSearch
                name="filter"
                onChange={this.handleChange}
                placeholder="Pesquisar jogadores"
              />
              <img
                onClick={this.onClearSearch}
                className={style.button}
                src={ImageClearFilter}
                alt="Limpar busca"
              />
            </label>
            <div className={`mb-separate-base ${style.containerRanking}`}>
              {loading ? (
                <div className="mt-separate-medium mb-separate-medium">
                  <Loading />
                </div>
              ) : (
                <RankingBar data={results} spacing="10px" />
              )}
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </>
    );
  }
}

export default Ranking;
